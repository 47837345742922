import React from 'react';
import Loader from 'components/Loader';
import NewInput from 'app/components/NewInput';
import RadioButton from 'app/shared/components/RadioButton';
import Dropdown from 'app/shared/components/Dropdown';

import './index.scss';
import { customObjectOptions } from 'app/json-data/dropdown-constant';
import Select from "react-select";

const CTAType = ({
  cta_type,
  handleChange,
  internalUrl,
  url,
  handleTextChange,
  internal_for,
  internal_url,
  profile_data,
  custom_object,
  redirection_id = '',
  isDisabled = false,
  secondaryCTA = false,
  cta_type_secondary,
  url_secondary,
  internal_for_secondary,
  internal_url_secondary,
  profile_data_secondary,
  custom_object_secondary,
  redirection_id_secondary,
  ctaText = false,
  detail_cta_text,
  detail_cta_text_secondary,
  cta_image='',
  ctaimage=false,
  selectedItem
}) => {
  const radioMenu = [
    {
      id: 'nocta',
      label: 'No Cta',
      value: null,
    },
    {
      id: 'internal_redirect',
      label: 'Internal Redirect',
      value: 'INTERNAL',
    },
    {
      id: 'internal_pwa',
      label: 'Internal PWA',
      value: 'INTERNAL_PWA',
    },
    {
      id: 'external_redirect_pwa',
      label: 'External Redirect(PWA)',
      value: 'EXTERNAL_PWA',
    },
    {
      id: 'external_redirect_outside_app',
      label: 'External Redirect(Outside APP)',
      value: 'EXTERNAL',
    },
  ];

  const genderRadioMenu = [
    {
      label: 'True',
      id: '1',
      value: true,
    },
    {
      label: 'False',
      id: '2',
      value: false,
    },
  ];
  // const customObjectOptions = [
  //   {
  //     label: 'PulmoRehab',
  //     id: '1',
  //     value: 'PulmoRehab',
  //   },
  //   {
  //     label: 'Exercise',
  //     id: '2',
  //     value: 'Exercise',
  //   },
  //   {
  //     label: 'SafeyPurchase',
  //     id: '3',
  //     value: 'SafeyPurchase'
  //   }
  // ]; 
  const handleInternalUrlChange = (selectedOption) => {
    handleChange('url', selectedOption ? selectedOption.value : '');
  };

  return (
    <>
      <div className='row'>
        {radioMenu.map((item, index) => (
          <div className='col-auto'>
            <RadioButton
              id={item.id}
              label={item.label}
              isChecked={cta_type == item.value ? true : false}
              value={item.value}
              onSelect={() => { 
                  handleChange('cta_type', item.value);  
              }}
              name={item.label}
              // style={{ marginTop: "-15px" }}
              isDisabled={isDisabled}
            />
          </div>
        ))}

        <div className='mt-3'>
          {cta_type === 'INTERNAL' && (
            <div className='dropdown-area'>
              <label>Internal App</label>
              {/* <select
                name='url'
                className='custom-select mx-2'
                onChange={(e) => {
                  handleChange('url', e.target.value);
                }}
                disabled={isDisabled}
              >
                <option value=''>Select</option>
                {internalUrl?.map((item, indx) => (
                  <option
                    key={indx}
                    value={item.value}
                    selected={url == item.value ? true : false}
                  >
                    {item.label}
                  </option>
                ))}
              </select> */}
              <Select
                options={internalUrl}
                value={internalUrl.find(option => option.value === url)}
                onChange={handleInternalUrlChange}
                isDisabled={isDisabled}
                placeholder="Search and select internal URL..."
                isClearable
                isSearchable
              />
            </div>
          )}

          {(url === 'zoom_live_session' ||
            url === 'infinite_scroll' ||
            url === 'group_of_collection' || url === 'landing_page' || url === 'breathing_exercise') && (
            <div className='input-container'>
              <NewInput
                label='Redirection Id'
                name='redirection_id'
                onChange={handleTextChange}
                value={redirection_id}
                isDisabled={isDisabled}
              />
            </div>
          )}

          {(cta_type === 'EXTERNAL' || cta_type === 'EXTERNAL_PWA') && (
            <div className='dropdown-area'>
              <NewInput
                label='External Redirect Link'
                name='url'
                value={url}
                onChange={handleTextChange}
                disabled={cta_type === 'INTERNAL'}
                isDisabled={isDisabled}
              />
            </div>
          )}

          {cta_type === 'INTERNAL_PWA' && (
            <>
              <NewInput
                label='For'
                name={'internal_for'}
                value={internal_for}
                placeholder='Enter for'
                className='form-block'
                type='text'
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                }}
                isDisabled={isDisabled}
              />
              <NewInput
                label='URL'
                name={'internal_url'}
                value={internal_url}
                placeholder='Enter URL'
                className='form-block'
                type='text'
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                }}
                isDisabled={isDisabled}
              />
              {/* <div className='radio_container'>
                <div className='content-selector customradio'>
                  <h6>Profile Data</h6>
                  {genderRadioMenu.map((item, key) => (
                    <RadioButton
                      key={key}
                      label={item.label}
                      isChecked={profile_data === item.value ? true : false}
                      value={item.value}
                      onSelect={() => {
                        handleChange('profile_data', item.value);
                      }}
                      name={item.label}
                      isDisabled={isDisabled}
                    />
                  ))}
                </div>
              </div> */}
              <Dropdown
                label='Custom Object'
                name='custom_object'
                options={customObjectOptions}
                placeholder='Select custom object'
                value={custom_object}
                handleChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                }}
                // handleChange={onChange}
                isDisabled={isDisabled}
              />
            </>
          )}
        </div>
        {cta_type && ctaText && (
          <div>
            <NewInput
              name='detail_cta_text'
              label={`${selectedItem === "sticky_payment_widget"?"CTA text type to take to payment screen":"Detail CTA Text"}`}
              value={detail_cta_text}
              onChange={handleTextChange}
            />
          </div>
        )}
        
      </div>
      {secondaryCTA && (
        <div className='row my-5'>
          <h5>{selectedItem === "sticky_payment_widget" ? "CTA text for passing the lead":"Secondary CTA"}</h5>
          {radioMenu.map((item, index) => (
            <div className='col-auto' key={'secondarycta' + '_' + index}>
              <RadioButton
                id={'secondarycta' + '_' + item.id}
                label={item.label}
                isChecked={cta_type_secondary == item.value ? true : false}
                value={item.value}
                onSelect={() => {
                  handleChange('cta_type_secondary', item.value);
                }}
                name={'secondarycta' + '_' + item.label}
                // style={{ marginTop: "-15px" }}
                isDisabled={isDisabled}
                key={'secondarycta' + '_' + index}
              />
            </div>
          ))}

          <div className='mt-3'>
            {cta_type_secondary === 'INTERNAL' && (
              <div className='dropdown-area'>
                <label>Internal App</label>
                <select
                  name='url_secondary'
                  className='custom-select mx-2'
                  onChange={(e) => {
                    handleChange('url_secondary', e.target.value);
                  }}
                  disabled={isDisabled}
                >
                  <option value=''>Select</option>
                  {internalUrl?.map((item, indx) => (
                    <option
                      key={indx}
                      value={item.value}
                      selected={url_secondary == item.value ? true : false}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {(url_secondary === 'zoom_live_session' ||
              url_secondary === 'infinite_scroll' ||
              url_secondary === 'group_of_collection' || url_secondary === 'landing_page') && (
              <div className='input-container'>
                <NewInput
                  label='Redirection Id'
                  name='redirection_id_secondary'
                  onChange={handleTextChange}
                  value={redirection_id_secondary}
                  isDisabled={isDisabled}
                />
              </div>
            )}

            {(cta_type_secondary === 'EXTERNAL' ||
              cta_type_secondary === 'EXTERNAL_PWA') && (
              <div className='dropdown-area'>
                <NewInput
                  label='External Redirect Link'
                  name='url_secondary'
                  value={url_secondary}
                  onChange={handleTextChange}
                  disabled={cta_type_secondary === 'INTERNAL'}
                  isDisabled={isDisabled}
                />
              </div>
            )}

            {cta_type_secondary === 'INTERNAL_PWA' && (
              <>
                <NewInput
                  label='For'
                  name={'internal_for_secondary'}
                  value={internal_for_secondary}
                  placeholder='Enter for'
                  className='form-block'
                  type='text'
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                  }}
                  isDisabled={isDisabled}
                />
                <NewInput
                  label='URL'
                  name={'internal_url_secondary'}
                  value={internal_url_secondary}
                  placeholder='Enter URL'
                  className='form-block'
                  type='text'
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                  }}
                  isDisabled={isDisabled}
                />
                <div className='radio_container'>
                  <div className='content-selector customradio'>
                    <h6>Profile Data</h6>
                    {genderRadioMenu.map((item, key) => (
                      <RadioButton
                        key={key}
                        label={item.label}
                        isChecked={
                          profile_data_secondary === item.value ? true : false
                        }
                        value={item.value}
                        onSelect={() => {
                          handleChange('profile_data_secondary', item.value);
                        }}
                        name={item.label}
                        isDisabled={isDisabled}
                      />
                    ))}
                  </div>
                </div>
                <Dropdown
                  label='Custom Object'
                  name='custom_object_secondary'
                  options={customObjectOptions}
                  placeholder='Select custom object'
                  value={custom_object_secondary}
                  handleChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                  }}
                  // handleChange={onChange}
                  isDisabled={isDisabled}
                />
              </>
            )}
          </div>
          {cta_type_secondary && ctaText && (
            <div>
              <NewInput
                name='detail_cta_text_secondary'
                // label={'Detailed CTA text'}
                label={`${selectedItem === 'sticky_payment_widget' ? 'CTA text for passing the lead':'Detailed CTA text'}`}
                value={detail_cta_text_secondary}
                onChange={handleTextChange}
              />
            </div>
          )}
        </div>
      )}
      {/* </div> */}
    </>
  );
};

export default CTAType;
